import { ReactElement, useMemo, useState } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { ThemeProvider } from "styled-components";
import { Navigate, Route, Routes, matchPath, useLocation } from "react-router-dom";
import { BlockedPerson, LogOut, Time } from "@remhealth/icons";

import {
  Button,
  IconProps,
  NonIdealState,
  Spinner,
  StorageContext,
  StoragePartition,
  Toaster,
  getTheme
} from "@remhealth/ui";

import {
  AccessToken,
  TrackingContext,
  VoidTrackingService,
  type Zone,
  practiceClients,
  registryClients,
  useAccessToken
} from "@remhealth/host";

import {
  AppContext,
  FullCenterScreen,
  GlobalLanguageContext,
  LoadingViewsProvider,
  NotFound,
  globalLanguageService
} from "@remhealth/core";

import { RequestHandler, zone as apolloZone } from "@remhealth/apollo";
import { MesaConfig } from "~/config";
import { sessionTimeoutMs } from "~/constants";
import { MesaContext } from "~/contexts";
import { Text } from "~/text";
import { Sidebar, routes } from "~/app";
import { Operations } from "~/operations";
import { Builds } from "~/builds";
import { Recommendations } from "../recommendations/recommendations";
import { ManageSnippetTemplates } from "../snippets";
import { ManageTextExpansionTemplates } from "../textExpansions";

import { Body, Main } from "./app.styles";

const sessionRedirectKey = "redirectPath";

interface SessionRefreshRedirect {
  lastKnownPath: string;
}

interface InvalidSessionMessage {
  icon: ReactElement<IconProps>;
  title: string;
  message: JSX.Element | string;
}

type SessionContext = StorageContext & MesaContext & GlobalLanguageContext;

export interface AppProps {
  config: MesaConfig;
  zone: Zone;
  version: string | undefined;
  toaster: Toaster;
  configLoader: (zone: Zone) => Promise<MesaConfig>;
  onError: (error: any) => void;
  onIdle?: () => void;
  onReauth: () => void;
  onLogout: () => void;
}

const theme = getTheme("light", 10);

export const App = (props: AppProps) => {
  const { config, zone, version, toaster, configLoader, onError, onIdle, onReauth, onLogout } = props;

  const token = useAccessToken();
  const location = useLocation();

  const appContext = useMemo<AppContext>(() => ({
    handleError: onError,
    reportError: () => { },
    identifierUrl: () => undefined,
    get notification() {
      return toaster;
    },
  }), [toaster]);

  const trackingContext = useMemo<TrackingContext>(() => ({
    tracking: new VoidTrackingService(),
  }), []);

  const watchForIdle = window.self !== window.top; // Disable idle in iframes
  const sessionContext = useMemo<SessionContext>(() => createSession(config, token), []);
  const [sessionTimedOut, setSessionTimedOut] = useState<InvalidSessionMessage | null>(null);

  return (
    <IdleTimerProvider disabled={!watchForIdle} timeout={sessionTimeoutMs} onIdle={handleIdle}>
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={appContext}>
          <TrackingContext.Provider value={trackingContext}>
            <LoadingViewsProvider>
              {renderInner()}
            </LoadingViewsProvider>
          </TrackingContext.Provider>
        </AppContext.Provider>
      </ThemeProvider>
    </IdleTimerProvider>
  );

  function renderInner() {
    // Render something while still logging user in
    if (!sessionContext) {
      return (
        <FullCenterScreen>
          <NonIdealState
            icon={<Spinner intent="primary" size={64} />}
            title={Text.LoadingProfile}
          />
        </FullCenterScreen>
      );
    }

    if (sessionTimedOut) {
      return (
        <FullCenterScreen>
          <NonIdealState
            description={sessionTimedOut.message}
            icon={sessionTimedOut.icon}
            title={sessionTimedOut.title}
          >
            <Button intent="danger" label="Sign In" onClick={handleSessionTimeoutLoginClick} />
          </NonIdealState>
        </FullCenterScreen>
      );
    }

    if (!token.hasClaim("rh.staff.role")) {
      return (
        <FullCenterScreen>
          <NonIdealState
            description="Your account does not appear to be a valid Bells.ai staff account."
            icon={<BlockedPerson />}
            title="Invalid account"
          >
            <Button icon={<LogOut />} intent="danger" label="Sign Out" onClick={onLogout} />
          </NonIdealState>
        </FullCenterScreen>
      );
    }

    const sessionRefreshVal = window.sessionStorage.getItem(sessionRedirectKey);
    if (sessionRefreshVal) {
      window.sessionStorage.removeItem(sessionRedirectKey);

      const sessionRefresh: SessionRefreshRedirect = JSON.parse(sessionRefreshVal);

      if (location.pathname !== sessionRefresh.lastKnownPath) {
        return (
          <Navigate to={sessionRefresh.lastKnownPath} />
        );
      }
    }

    return (
      <MesaContext.Provider value={sessionContext}>
        <GlobalLanguageContext.Provider value={sessionContext}>
          <StorageContext.Provider value={sessionContext}>
            <Main>
              <Sidebar {...props} />
              <Body>
                <Routes>
                  <Route element={<Operations config={config} zone={zone} />} path="operations/*" />
                  <Route element={<Builds config={config} />} path="builds/*" />
                  <Route element={<Recommendations />} path="recommendations/*" />
                  <Route element={<ManageSnippetTemplates />} path="drop-ins/*" />
                  <Route element={<ManageTextExpansionTemplates />} path="expansions/*" />
                  <Route element={<Navigate replace to={routes.operations} />} path="/" />
                  <Route element={<NotFound />} path="*" />
                </Routes>
              </Body>
            </Main>
          </StorageContext.Provider>
        </GlobalLanguageContext.Provider>
      </MesaContext.Provider>
    );
  }

  function createSession(config: MesaConfig, token: AccessToken): SessionContext {
    const injectHeaders: Record<string, string> = {
      "X-RH-ApplicationID": "mesa",
      "X-RH-ApplicationVersion": version ?? "",
      "X-RH-DeclaredUser": token.firstClaim("sub") ?? "",
    };

    const apolloRequestHandler: RequestHandler = {
      onBeforeRequest: async (requestConfig) => {
        const { headers, ...rest } = requestConfig;
        headers.set(injectHeaders);
        return { headers, ...rest };
      },
    };

    const langService = globalLanguageService({
      baseURL: config.urls.language,
      application: "mesa",
      zone: apolloZone[zone],
      headers: injectHeaders,
      accessToken: token,
    });

    return {
      languageService: langService,
      storage: {
        local: new StoragePartition(localStorage, "mesa"),
        session: new StoragePartition(sessionStorage, "mesa"),
      },
      injectHeaders,
      config,
      registry: registryClients(config.urls.apollo, token, apolloRequestHandler),
      practice: (networkId: string) => practiceClients(config.urls.apollo, token, { networkId }, {
        onBeforeRequest: async (requestConfig) => {
          const { headers, ...rest } = requestConfig;
          headers.set(injectHeaders);
          headers.set("X-RH-Practice", networkId ?? "");
          return { headers, ...rest };
        },
      }),
      configLoader,
    };
  }

  function handleIdle() {
    setSessionTimedOut({
      icon: <Time />,
      title: "Session Timeout",
      message: <>Sorry, you have been idle for more than ten minutes.<br />You have been logged out.</>,
    });

    onIdle?.();
  }

  function handleSessionTimeoutLoginClick() {
    if (sessionContext
      && location.pathname
      && !matchPath(routes.root, location.pathname)
      && !matchPath(routes.logout, location.pathname)
    ) {
      const value: SessionRefreshRedirect = {
        lastKnownPath: location.pathname,
      };

      window.sessionStorage.setItem(sessionRedirectKey, JSON.stringify(value));
    }

    onReauth();
  }
};
